export default function elementIsInDOM( element: HTMLElement, root: HTMLElement = document.body ) :boolean {
	if ( !element ) {
		return false;
	}

	if ( element === root ) {
		return false;
	}

	return root.contains( element );
}
