import { BaseController } from '../controllers/base';

function convertAttributeToPropertyName( attribute: string ): string {
	return attribute.split( '-' ).reduce( ( camelcased, part, index ) => {
		if ( 0 === index ) {
			return part;
		}

		return camelcased + part[0].toUpperCase() + part.substr( 1 );
	} );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
function addMethod<T extends BaseController<U|HTMLElement>, U extends HTMLElement>( controllerType: new ( el: U | HTMLElement ) => T, name: string, method: any ): void {
	if ( 'undefined' !== typeof controllerType.prototype[name] ) {
		console.warn( `${controllerType.name} already has a property ${name}` );
	}

	controllerType.prototype[name] = method;
}

function addGetter<T extends BaseController<U|HTMLElement>, U extends HTMLElement, V>( controllerType: new ( el: U | HTMLElement ) => T, name: string, method: () => V|null ): void {
	const getterName = convertAttributeToPropertyName( name );

	if ( 'undefined' !== typeof controllerType.prototype[getterName] ) {
		console.warn( `${controllerType.name} already has a property ${getterName}` );
	}

	Object.defineProperty( controllerType.prototype, getterName, {
		configurable: false,
		get: method,
	} );
}

function addSetter<T extends BaseController<U|HTMLElement>, U extends HTMLElement, V>( controllerType: new ( el: U | HTMLElement ) => T, name: string, method: ( x:V|null ) => void ): void {
	const setterName = convertAttributeToPropertyName( name );

	if ( 'undefined' !== typeof controllerType.prototype[setterName] ) {
		console.warn( `${controllerType.name} already has a property ${setterName}` );
	}

	Object.defineProperty( controllerType.prototype, setterName, {
		configurable: false,
		set: method,
	} );
}

function addProperty<T extends BaseController<U|HTMLElement>, U extends HTMLElement, V>( controllerType: new ( el: U | HTMLElement ) => T, name: string, getter: ( () => V|null )|null = null, setter: ( ( x:V|null ) => void )|null = null ): void {
	const propertyName = convertAttributeToPropertyName( name );

	if ( 'undefined' !== typeof controllerType.prototype[propertyName] ) {
		console.warn( `${controllerType.name} already has a property "${propertyName}"` );
	}

	let g = function(): V | null {
		return null;
	};

	if ( ( 'function' === typeof getter ) ) {
		g = getter;
	}

	/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
	let s = function( x: V | null ): void {
		return;
	};

	if ( ( 'function' === typeof setter ) ) {
		s = setter;
	}

	const property = {
		configurable: false,
		get: g,
		set: s,
	};

	let descriptor;
	// https://stackoverflow.com/questions/55193973/cannot-read-property-disguisetoken-of-undefined
	// These try/catch blocks might help us gain more insights
	try {
		descriptor = Object.getOwnPropertyDescriptor( controllerType.prototype, propertyName );
	} catch ( err ) {
		// "Cannot read property 'disguiseToken' of undefined"
		// We can't pinpoint this error and are phasing out CEH.
		console.warn( err );
	}

	if ( descriptor ) {
		console.warn( `${controllerType.name} already has a property "${propertyName}"` );

		if ( 'function' === typeof descriptor.set ) {
			const existing = descriptor.set;

			property.set = function set( to ) {
				existing.call( this, to );
			};
		}

		if ( 'function' === typeof descriptor.get ) {
			const generated = property.get;
			const existing = descriptor.get;

			property.get = function get() {
				const value = existing.call( this );

				if ( 'undefined' !== typeof value ) {
					return value;
				}

				return generated.call( this );
			};
		}
	}

	Object.defineProperty( controllerType.prototype, propertyName, property );
}

export {
	convertAttributeToPropertyName,
	addMethod,
	addGetter,
	addSetter,
	addProperty
};
