import { convertAttributeToPropertyName, addProperty } from '../internal/decorators';
import { generateAttributeMethods } from '../internal/attribute-methods-generator';
import { BaseController } from '../controllers/base';

const CONTROLLER = Symbol( 'controller' );

function registerElement<T extends BaseController<U|HTMLElement>, U extends HTMLElement>( tag: string, options: DefineElementOptions<T, U> ) {
	const observedAttributes = options.observedAttributes || [];
	const Controller: new ( el: HTMLElement ) => T = options.controller;

	customElements.define( tag, class extends HTMLElement {
		[CONTROLLER]: T|null = null;

		static get observedAttributes() {
			return observedAttributes;
		}

		attributeChangedCallback( attribute: string, oldValue: string|null, newValue: string|null ): void {
			if ( oldValue === newValue ) {
				return;
			}

			const ctrl = this[CONTROLLER];
			if ( !ctrl ) {
				return;
			}

			const propertyName = convertAttributeToPropertyName( attribute );

			const prototype = Object.getPrototypeOf( ctrl );
			const propertyDescriptor = Object.getOwnPropertyDescriptor( prototype, propertyName );

			if ( propertyDescriptor && propertyDescriptor.set ) {
				propertyDescriptor.set.call( ctrl, newValue );
			}

			// If for argument `current` the method
			// `currentChangedCallback` exists, trigger
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const callback = ( <any> ctrl )[`${propertyName}ChangedCallback`];

			if ( 'function' === typeof callback ) {
				callback.call( ctrl, oldValue, newValue );
			}
		}

		constructor() {
			super();

			observedAttributes.forEach( ( attribute ) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				if ( 'undefined' !== typeof( <any> this )[attribute] ) {
					console.warn( `Requested syncing on attribute '${attribute}' that already has defined behavior` );
				}

				Object.defineProperty( this, attribute, {
					configurable: false,
					enumerable: false,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					get: (): any => {
						const ctrl = this[CONTROLLER];
						if ( !ctrl ) {
							return null;
						}

						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						return ( <any> ctrl )[attribute];
					},
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					set: ( to: any ): void => {
						const ctrl = this[CONTROLLER];
						if ( !ctrl ) {
							return;
						}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						( <any> ctrl )[attribute] = to;
					},
				} );
			} );
		}

		connectedCallback() {
			// eslint-disable-next-line new-cap
			this[CONTROLLER] = new Controller( this );
		}

		disconnectedCallback() {
			const ctrl = this[CONTROLLER];
			if ( !ctrl ) {
				return;
			}

			ctrl.unbind();
			ctrl.destroy();

			this[CONTROLLER] = null;
		}
	} );
}

interface AttributeOptions {
	type: string
	attribute: string
}

function addAttributesToController<T extends BaseController<U|HTMLElement>, U extends HTMLElement>( controller: new ( el: U | HTMLElement ) => T, attributes: Array<string|AttributeOptions> = [] ) {
	const out: Array<string> = [];

	attributes.forEach( ( attribute ) => {
		// String, sync with actual element attribute
		if ( 'string' === typeof attribute ) {
			const {
				getter, setter,
			} = generateAttributeMethods( attribute, 'string' );
			addProperty( controller, attribute, getter, setter );

			out.push( attribute );

			return;
		}

		if ( 'object' === typeof attribute ) {
			const type = attribute.type || 'string';
			const name = attribute.attribute;
			const {
				getter, setter,
			} = generateAttributeMethods( name, type );
			addProperty( controller, name, getter, setter );

			out.push( name );

			return;
		}

	} );

	return out;
}

interface DefineElementOptions<T extends BaseController<U|HTMLElement>, U extends HTMLElement> {
	controller: new ( el: U | HTMLElement ) => T
	extends?: null | ( new () => U | HTMLElement )
	attributes?: Array<string|AttributeOptions>
	observedAttributes?: Array<string> | null
	type?: string | null
}

export default function defineCustomElement<T extends BaseController<U|HTMLElement>, U extends HTMLElement>( tag: string, options: DefineElementOptions<T, U> ): void {
	// Validate tag
	const isValidTag = 1 < tag.split( '-' ).length;

	// Validate type
	// Needs a freaking fallback default value...
	options.type = options.type || 'element';
	if ( 'element' !== options.type ) {
		console.warn( 'attribute definitions are no longer supported as they are stupid! Refactor : ' + tag );

		return;
	}

	// Validate attributes
	let attributes: Array<string|AttributeOptions> = [];
	if ( Array.isArray( options.attributes ) ) {
		attributes = options.attributes;
	}

	// Validate controller
	const controller = options.controller;
	const _extends = options.extends;

	if ( !isValidTag ) {
		console.warn( tag, 'is not a valid Custom Element name. Register as an attribute instead.' );

		return;
	}

	if ( _extends ) {
		console.warn( '`extends` is not supported on element-registered Custom Elements. Register as an attribute instead.' );

		return;
	}

	const observedAttributes = addAttributesToController( controller, attributes );

	const validatedOptions = {
		type: 'element',
		extends: null,
		attributes: attributes,
		controller: controller,
		observedAttributes: observedAttributes,
	};

	return registerElement( tag, validatedOptions );
}
