interface EventDescriptor {
	event: string
	selector: string | null
}

export function parse( name: string ) :EventDescriptor {
	const clean = name.trim();
	const parts = clean.split( ' ' );

	let event = clean;
	let selector = null;

	if ( 1 < parts.length ) {
		event = parts[0];
		selector = parts.slice( 1 ).join( ' ' );
	}

	return {
		event,
		selector,
	};
}

declare global {
	interface Event {
		// Non-standard but used in older browsers
		path: NodeList|Array<Node>|null
	}
}

export function getPath( e: Event ): Array<Document|Element|Node|Window> {
	if ( e.path ) {
		if ( Array.isArray( e.path ) ) {
			return e.path;
		}

		return Array.from( e.path );
	}

	if ( e.composedPath ) {
		const composedPath = e.composedPath();
		const out: Array<Document|Element|Node|Window> = [];

		composedPath.forEach( ( eventTarget ) => {
			if (
				eventTarget instanceof Document ||
				eventTarget instanceof Element ||
				eventTarget instanceof Node ||
				eventTarget instanceof Window
			) {
				out.push( eventTarget );

				return;
			}
		} );

		return out;
	}

	if ( e.target && e.target instanceof Node ) {
		const out: Array<Document | Element | Node | Window> = [
			e.target,
		];

		let node = e.target;
		while ( node instanceof Node && node.parentNode ) {
			node = node.parentNode;
			out.push( node );
		}

		return out;
	}

	return [];
}
