export default function waitForDOMReady(): Promise<void> {
	return new Promise( ( resolve ) => {
		if ( isInteractive() ) {
			resolve();

			return;
		}

		const handler = () => {
			if ( isInteractive() ) {
				document.removeEventListener( 'readystatechange', handler, false );
				resolve();
			}
		};

		document.addEventListener( 'readystatechange', handler, false );
	} );
}

function isInteractive(): boolean {
	return (
		'complete' === document.readyState || 'interactive' === document.readyState
	);
}
