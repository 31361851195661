let observer: IntersectionObserver | null = null;
if ( 'IntersectionObserver' in window ) {
	observer = new IntersectionObserver( ( entries ) => {
		for ( const entry of entries ) {
			if ( !( entry.target instanceof HTMLVideoElement ) ) {
				continue;
			}

			if ( entry.isIntersecting ) {
				play( entry.target );
			} else {
				pause( entry.target );
			}
		}
	} );
}

// Respect the user's choice to reduce motion
const userPrefersReducedMotion = window.matchMedia( '(prefers-reduced-motion: reduce)' );
userPrefersReducedMotion.addEventListener( 'change', () => {
	if ( userPrefersReducedMotion.matches ) {
		getVideos().forEach( ( video ) => {
			if ( videoClaimsToSupportPrefersReducedMotion( video ) ) {
				pause( video );
			}
		} );

		return;
	}
} );

// You shall not waste things from the background!
// Stop video playback when the page is hidden (user went to another tab)
document.addEventListener( 'visibilitychange', () => {

	// pause all videos
	if ( 'hidden' === document.visibilityState ) {
		getVideos().forEach( ( video ) => {
			pause( video );
		} );

		return;
	}

	if ( 'visible' === document.visibilityState ) {
		if ( observer ) {
			// unpause all visible videos
			for ( const entry of observer.takeRecords() ) {
				if ( !( entry.target instanceof HTMLVideoElement ) ) {
					continue;
				}

				if ( entry.isIntersecting ) {
					play( entry.target );
				}
			}
		} else {
			// unpause all videos on Legacy browsers
			getVideos().forEach( ( video ) => {
				play( video );
			} );
		}
	}

}, false );

init();
document.addEventListener( 'readystatechange', init, false );
document.addEventListener( 'autoplay-in-view:do-init', init, false );

function init(): void {
	getVideos().forEach( ( video ) => {
		if ( observer ) {
			observer.observe( video );

			return;
		}

		video.setAttribute( 'autoplay', '' );
		play( video );
	} );
}

/** Unpause the video element
 *
 * IE may not return a promise. we assume it will Just Work (tm)(c)(r)
 * */
async function play( video: HTMLVideoElement ): Promise<void> {
	if ( !video.src && !video.currentSrc ) {
		return;
	}
	if ( !video.paused ) {
		return;
	}

	if ( userPrefersReducedMotion.matches && videoClaimsToSupportPrefersReducedMotion( video ) ) {
		return;
	}

	try {
		await video.play();

		// When the "controls" attribute is present the theme author is using these as a fallback for autoplay.
		// If we can start playback we remove these.
		video.removeAttribute( 'controls' );

		// If we suddenly can start playback, remove the network/ready state tracking.
		hidePlaybackableState( video );

	} catch ( err ) {
		let videoError = null;
		if ( video.error ) {
			videoError = {
				code: video.error.code,
				message: video.error.message,
			};
		}

		console.log( {
			module: '@mrhenry/wp--autoplay-in-view',
			message: 'start playback for video in viewport failed',
			videoSrc: video.src,
			error: videoError,
			readyState: video.readyState,
			networkState: video.networkState,
		} );

		exposePlaybackableState( video );

		video.removeEventListener( 'click', clickAfterFailedPlayAttemptEventHandler );
		video.addEventListener( 'click', clickAfterFailedPlayAttemptEventHandler, {
			once: true,
		} );

		if ( isUnexpectedError( err ) && ( 'bugsnagClient' in window ) ) {
			window.bugsnagClient.notify( err );
		} else {
			console.warn( err );
		}
	}
}

function getVideos(): NodeListOf<HTMLVideoElement> {
	return document.querySelectorAll( 'video[data-autoplay-in-view]' );
}

function pause( video: HTMLVideoElement ): void {
	if ( video.paused ) {
		return;
	}

	video.pause();
}

// Some errors do not indicate a code bug.
// They are the result of expected device settings, user interactions or a broken video.
// We do not report this, but output as console.warn
function isUnexpectedError( err: Error ): boolean {
	if ( err ) {
		switch ( err.name ) {
			case 'AbortError':
			case 'PlayInterrupted':
			case 'NotAllowedError':
			case 'NotSupportedError':
				return false;
			default:
			// docs are vague on error type/class checking.
			// add a log to collect some data
				console.log( err.name );
		}
	}

	return true;
}

function exposePlaybackableState( video: HTMLVideoElement ): void {
	// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
	if ( 2 <= video.readyState ) {
		// We at minimum need "HAVE_CURRENT_DATA" which has a value of 2
		return;
	}

	// "readyState" and "networkState" are exposed on error.
	video.setAttribute( 'data-aiv-ready-state', video.readyState.toString() );
	video.setAttribute( 'data-aiv-network-state', video.networkState.toString() );

	// Only add one event listener
	if ( !video.hasAttribute( 'data-aiv-tracking-ready-state' ) ) {
		video.setAttribute( 'data-aiv-tracking-ready-state', '' );

		// If the video element starts loading data we update state.
		video.addEventListener( 'loadeddata', playbackableStateEventHandler );
	}
}

// "readyState" and "networkState" are exposed on error.
// If we can start playback we remove these.
function hidePlaybackableState( video: HTMLVideoElement ): void {
	video.removeAttribute( 'data-aiv-ready-state' );
	video.removeAttribute( 'data-aiv-network-state' );
	video.removeAttribute( 'data-aiv-tracking-ready-state' );
	video.removeEventListener( 'loadeddata', playbackableStateEventHandler );
}

function playbackableStateEventHandler( this: HTMLVideoElement ) {
	// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
	if ( 2 > this.readyState ) {
		// We at minimum need "HAVE_CURRENT_DATA" which has a value of 2
		this.setAttribute( 'data-aiv-ready-state', this.readyState.toString() );
		this.setAttribute( 'data-aiv-network-state', this.networkState.toString() );

		return;
	}

	hidePlaybackableState( this );
}

async function clickAfterFailedPlayAttemptEventHandler( this: HTMLVideoElement, event: Event ) {
	if ( !event.isTrusted ) {
		return;
	}

	if ( this.controls ) {
		return;
	}

	if ( !this.paused ) {
		return;
	}

	event.preventDefault();
	event.stopPropagation();

	try {
		await this.play();

		// If we suddenly can start playback, remove the network/ready state tracking.
		hidePlaybackableState( this );

	} catch ( err ) {
		// second attempt to start playback failed.
		// we do not report this, but output as console.warn
		console.warn( err );
	}
}

function videoClaimsToSupportPrefersReducedMotion( video: HTMLVideoElement ): boolean {
	// Only video's that have a play/pause button should claim this.
	return video.matches( '[data-autoplay-in-view~="ignore-when-prefers-reduced-motion"]' );
}
